import { GeneralType } from "@/shared/types";


import { createSlice, PayloadAction } from "@reduxjs/toolkit";



const initialState: GeneralType = {
    IRKey: null,
}


export const generalSlice = createSlice({


    name: 'general',
    initialState,
    reducers: {
        setIRkey: (state, action: PayloadAction<string>) => {
            state.IRKey = action.payload
        }
    }


})

export const { setIRkey } = generalSlice.actions

export default generalSlice.reducer