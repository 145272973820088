import { createSlice, PayloadAction } from '@reduxjs/toolkit'


import { localType, Button } from '@/shared/types'


const initialState: localType = {
    mode: 'light',
    deviceList: [],
    codeList: [],
    usbDevice: null,
    resPayload: [],
    buttons: [],
    usbReader: null
}

const localSlice = createSlice({
    name: 'local',
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === 'light' ? 'dark' : 'light'
        },
        setDeviceList: (state, action: PayloadAction<any>) => {
            state.deviceList = action.payload
        },
        setCodeList: (state, action: PayloadAction<any>) => {
            state.codeList = action.payload
        }, setUsbPair: (state, action: PayloadAction<any>) => {
            state.usbDevice = action.payload
        }
        , setUsbReader: (state, action: PayloadAction<any>) => {
            state.usbReader = action.payload
        }
        , setPayLoad: (state, action: PayloadAction<string>) => {

            const updatedPayload = state.resPayload

            console.log('if you see -1, then the array does not that key', updatedPayload.indexOf(action.payload))
            if (updatedPayload.indexOf(action.payload) === -1) {
                console.log('before push', state.resPayload)
                updatedPayload.push(action.payload)

                state.resPayload = updatedPayload
                console.log('after push', state.resPayload)
            }




        }, setButtons: (state, action: PayloadAction<Button>) => {

            const updatedButton = state.buttons
            updatedButton.push(action.payload)


            state.buttons = updatedButton
        }, setButton: (state, action: PayloadAction<Button>) => {

            const updatedButton = state.buttons.map(button => {

                if (button.command === action.payload.command) {
                    return action.payload
                }
                return button


            })

            state.buttons = updatedButton
        }, setRemoveButton: (state, action: PayloadAction<string>) => {

            const updatedButton = state.buttons.filter(button =>
                button.id !== action.payload
            )

            state.buttons = updatedButton
        }
    }
})


export const { setCodeList, setRemoveButton, setDeviceList, setMode, setUsbPair, setButtons, setPayLoad, setButton, setUsbReader } = localSlice.actions

export default localSlice.reducer

