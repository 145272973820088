import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import { useMemo, lazy, Suspense } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { useSelector } from "react-redux";

//import ControlPage from "@/scenes/controlPage";
//import HomePage from "@/scenes/homePage";
import LoginPage from "@/scenes/loginPage";

const ControlPage = lazy(() => import("@/scenes/controlPage"));
const HomePage = lazy(() => import("@/scenes/homePage"));

import { RootState } from "./main";
import { themeSetting } from "./theme";
import LinearProgress from "@mui/material/LinearProgress";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const mode = useSelector((state: RootState) => state.local.mode);
  const theme = useMemo(() => createTheme(themeSetting(mode)), [mode]);
  const client = new QueryClient();
  const IsDevice = useSelector((state: RootState) => state.local.usbDevice);

  return (
    <div className="app">
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route
                path="/home"
                element={
                  <Suspense fallback={<LinearProgress />}>
                    <HomePage />
                  </Suspense>
                }
              />
              <Route
                path="/control"
                element={
                  IsDevice ? (
                    <Suspense fallback={<LinearProgress />}>
                      <ControlPage />
                    </Suspense>
                  ) : (
                    <Navigate to="/home" />
                  )
                }
              />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
