import { createSlice, PayloadAction } from '@reduxjs/toolkit'


import { AuthType } from '@/shared/types'



const initialState: AuthType = {
    user: null,
    msg: null
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLogin: (state, action: PayloadAction<any>) => {
            state.user = action.payload
        },
        setLogout: (state) => {
            state.user = null
        }, setMsg: (state, action: PayloadAction<string>) => {
            state.msg = action.payload
        }
    }
})

export const { setLogin, setLogout, setMsg } = authSlice.actions

export default authSlice.reducer


